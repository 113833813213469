<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form ref="dataInfo" class="twice padding" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-form-item label="企业编号" prop="enterpriseCode">
        <el-input v-model="dataInfo.enterpriseCode" autocomplete="off" :disabled="dataInfo.isAdd !== true" placeholder="企业编号,E大写字母开头,不可重复例如：E008(东华汽贸)"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="enterpriseName">
        <el-input v-model="dataInfo.enterpriseName" autocomplete="off" placeholder="企业的简写名称,例如:东华汽贸"></el-input>
      </el-form-item>
      <el-form-item label="企业全称" prop="enterpriseFullName">
        <el-input v-model="dataInfo.enterpriseFullName" autocomplete="off" placeholder="企业的完整名称,例如:南京东华汽车贸易有限公司"></el-input>
      </el-form-item>
      <el-form-item label="管理员账号" prop="adminUserName">
        <el-input v-model="dataInfo.adminUserName" autocomplete="off" :disabled="dataInfo.isAdd !== true" placeholder="管理员账号[初始默认密码zrt@2020],例如：dhqm(东华汽贸)"></el-input>
      </el-form-item>
      <el-form-item label="企业法人" prop="responsiblePeople">
        <el-input v-model="dataInfo.responsiblePeople" autocomplete="off" placeholder="企业的法人名称"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="linkPeople">
        <el-input v-model="dataInfo.linkPeople" autocomplete="off" placeholder="企业的管理人员名称"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="linkPhone">
        <el-input v-model="dataInfo.linkPhone" autocomplete="off" placeholder="企业的管理人员联系电话"></el-input>
      </el-form-item>
      <el-form-item label="企业地址" prop="address">
        <el-input v-model="dataInfo.address" autocomplete="off" placeholder="企业当前的办公地址"></el-input>
      </el-form-item>
      <el-form-item label="邮编" prop="zipCode">
        <el-input v-model="dataInfo.zipCode" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="服务电话" prop="servicePhone">
        <el-input v-model="dataInfo.servicePhone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="网站地址" prop="enterpriseUrl">
        <el-input v-model="dataInfo.enterpriseUrl" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="注册地址" prop="registerAddress">
        <el-input v-model="dataInfo.registerAddress" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="营业执照号" prop="licenseNo">
        <el-input v-model="dataInfo.licenseNo" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="success" v-if="dataInfo.isAdd === true" @click="add">确 定 开 户</el-button>
      <el-button type="primary" v-else @click="update">确 认 修 改</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      departList: null,
      dataRules: {
        enterpriseCode: [{ required: true, trigger: 'blur', message: '企业编号不能为空' }],
        enterpriseName: [{ required: true, trigger: 'blur', message: '企业名称不能为空' }],
        enterpriseFullName: [{ required: true, trigger: 'blur', message: '企业全称不能为空' }],
        adminUserName: [{ required: true, trigger: 'blur', message: '企业管理员账号不能为空' }]
      }
    }
  },
  methods: {
    add() {
      this.$refs.dataInfo.validate(valid => {
        if (valid) {
          this.$lgh.msgConfirm('确认企业开户?', async () => {
            await this.$lgh.post('api/enterprise/add', this.dataInfo)
            this.visible = false
            this.onsuccess && this.onsuccess(this.dataInfo)
          })
        }
      })
    },
    update() {
      this.$refs.dataInfo.validate(valid => {
        if (valid) {
          this.$lgh.msgConfirm('确认保存企业信息?', async () => {
            await this.$lgh.post('api/enterprise/update', this.dataInfo)
            this.visible = false
            this.onsuccess && this.onsuccess(this.dataInfo)
          })
        }
      })
    }
  }
}
</script>

<style></style>
